import React, { useContext, useMemo, useCallback, useState } from 'react';
import { useAsyncError } from '../../../api/error';

import { ModalContext } from '../../../context/ModalContext';
import { SnackbarContext } from '../../../context/SnackbarContext';
import { downloadFile, downloadFolder } from '../../../api/download';

import { useFolderPath } from '../api/getFolderPath';
import { useOrgDetail } from '../../organization/api/getOrgDetail';
import { CONFERENCE_APP_TITLE } from './Conference/ConferenceTitle';
import { isConferencePage } from './Conference/StorageConference';

import {
  deleteFile,
  deleteFolder,
  folder2series,
  series2folder,
} from '../../../api/rest';
import {
  checkFullAccess,
  checkCanEdit,
  checkCanComment,
  checkCanView,
} from '../../../utils/checkAccessPerm';
import { filetypeExtract } from '../../../utils/dataProcess';
import { useFolderInfo, useFolderInfoReset } from '../api/getFolderInfo';
import { useOrgRootList } from '../../organization/api/getOrgRootList';
import { useCheckOrgPerm } from '../../organization/api/getOrgUserPerm';

import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import LinkOutlinedIcon from '@material-ui/icons/LinkOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import OpenInNewOutlined from '@material-ui/icons/OpenInNewOutlined';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import CloudUploadOutlinedIcon from '@material-ui/icons/CloudUploadOutlined';
import CreateNewFolderOutlinedIcon from '@material-ui/icons/CreateNewFolderOutlined';
import BurstModeOutlinedIcon from '@material-ui/icons/BurstModeOutlined';
import DoubleArrowOutlinedIcon from '@material-ui/icons/DoubleArrowOutlined';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import SettingsOutlinedIcon from '@material-ui/icons/Settings';

import { Menu, MenuList, ListItemText } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';

import ListItemIcon from '@material-ui/core/ListItemIcon';

import Fade from '@material-ui/core/Fade';
import Typography from '@material-ui/core/Typography';
import { useFetchUpdateInfo } from '../api/fetchUpdateInfo';
import { useSetSelectDataCurrent } from '../utils/useSelectData';
import { useIsCheck } from '../utils/useParams';

import { generateUniviewerUrl } from '../../univiewer/UniversalViewerContainer';

const itemStyle = {
  margin: '0 10 0 0',
};

function ContextMenu(props) {
  const isConference = isConferencePage();
  const history = useHistory();
  const throwError = useAsyncError();

  // for update
  const { refetch: refetchOrgRootList } = useOrgRootList();
  const { refetch: refetchOrgDetail } = useOrgDetail();
  const { data: folderPath, isLoading: isLoadingFolderPath } = useFolderPath();
  const { data: folderInfo, isLoading: isLoadingFolderInfo } = useFolderInfo();
  const refetchFolderInfo = useFolderInfoReset();

  const {
    data: { memberPerm },
  } = useCheckOrgPerm();

  const {
    openShareModal,
    openDeleteDialog,
    openRenameDialog,
    openRenameURLFileDialog,
    openCreateURLFileDialog,
    openCreateFolderDialog,
    openCreateRootFolderDialog,
    openDicomTagsDialog,
    openMovePopover,
    openFolderSettingsDialog,
  } = useContext(ModalContext);

  const {
    setSnackbarMessage,
    setOpenSnackbar,
    setOpenSnackbarCollapse,
    setProgressValue,
    handleSnackbarCompleted,
  } = useContext(SnackbarContext);

  const fetchUpdateInfo = useFetchUpdateInfo();
  const { isRootPage } = useIsCheck();

  // データの権限や種類判定
  const fullAccess = checkFullAccess(props.data.perm);
  const canEdit = checkCanEdit(props.data.perm);
  const canComment = checkCanComment(props.data.perm);
  const canView = checkCanView(props.data.perm);

  const isFolder = props.data.object_type === 'folder';
  const isFile = props.data.object_type === 'file';

  const extractedFiletype = filetypeExtract(props.data.filetype);
  const isURLFile =
    props.data.object_type === 'file' && extractedFiletype === 'url';

  const isImgFile =
    props.data.object_type === 'file' &&
    (extractedFiletype === 'png' ||
      extractedFiletype === 'jpg' ||
      extractedFiletype === 'jpeg');

  const isFolderStrict =
    props.data.object_type === 'folder' && props.data.foldertype === 'folder';
  const isImgSeries =
    props.data.object_type === 'folder' &&
    props.data.foldertype === 'imgSeries';
  const isSeries =
    props.data.object_type === 'folder' && props.data.foldertype === 'series';
  const isDicom =
    props.data.object_type === 'folder' &&
    (props.data.foldertype === 'study' || props.data.foldertype === 'series'); // imgSeries はメタデータが無いので含めない

  const isCurrentFolder =
    isFolder &&
    !isLoadingFolderInfo &&
    Object.keys(folderInfo.current_folder).length > 0 &&
    folderInfo.current_folder.uuid === props.data.uuid;

  // 関数
  const openViewer = useCallback(
    ({ newTab }) => {
      const getUrl = () => {
        if (isFolder) {
          return `/viewer?url=/api/datamanage/dcm/folder/${props.data.uuid}`;
        } else if (isImgFile) {
          return `/viewer?url=/api/datamanage/dcm/fileimage/${props.data.uuid}&dataType=file`;
        } else if (isFile) {
          // png,jpg以外のファイル(つまりdicom単体)
          return `/viewer?url=/api/datamanage/dcm/file/${props.data.uuid}`;
        } else if (isImgSeries) {
          return `/viewer?url=/api/datamanage/dcm/folder/${props.data.uuid}`;
        } else {
          throwError('Not supported object type');
        }
      };

      // 現在のSPAまたは新しいタブで開く
      // console.debug(`url: ${getUrl()} newTab: ${newTab}`);
      if (newTab) {
        window.open(getUrl(), '_blank');
      } else {
        history.push(getUrl());
      }
    },
    [
      history,
      isFile,
      isFolder,
      isImgFile,
      isImgSeries,
      props.data.uuid,
      throwError,
    ]
  );

  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const deleteData = useCallback(async () => {
    setOpenSnackbar(true);
    setSnackbarMessage('削除しています...');
    if (isFolder) {
      await deleteFolder(props.data.uuid);
    } else {
      await deleteFile(props.data.uuid);
    }

    // 組織のデータ更新により容量の変更を反映する
    await refetchOrgDetail();

    // 左パネルを更新＆必要があればrootListの更新
    if (
      props.data.object_type === 'folder' &&
      props.data.parent_folder !== null
    ) {
      await refetchFolderInfo({
        folderUUID: props.data.parent_folder,
        apiOptions: { noFile: true, noUrl: true, combine: false },
      });
    } else {
      await refetchOrgRootList();
    }

    if (isCurrentFolder) {
      /* 閲覧中のcurrent folderを削除する時はfolderPath の一つ前にジャンプ
     root で削除はありえない(rootで削除しない)ので考慮しなくて良い
     一覧表示でのフォルダやファイルの削除時はジャンプしない
      */
      if (!isLoadingFolderPath && folderPath.length > 1) {
        const folderOrRoot = folderPath[folderPath.length - 2];
        if (folderOrRoot.object_type === 'no_perm') {
          // 一つ上が権限なしならorgのトップに
          if (props.data.org !== null) {
            history.push(`/storage/org/${props.data.org}`);
          } else {
            history.push('/');
          }
        } else if (folderOrRoot.object_type === 'folder') {
          // 一つ上がフォルダならデータ更新してそこにジャンプ
          await refetchFolderInfo({ folderUUID: props.data.parent_folder });
          history.push(`/storage/${folderOrRoot.uuid}`);
        } else if (
          folderOrRoot.type === 'private' ||
          folderOrRoot.type === 'share' ||
          folderOrRoot.type === 'org'
        ) {
          // 一つ上が root, private, share ならそこにジャンプ
          history.push(
            `/storage/org/${props.data.org}/root/${folderOrRoot.type}`
          );
        }
      } else {
        // ありえないが、一応処理を書いておく
        history.push('/');
      }
    } else if (!isRootPage()) {
      // current folderを削除せず、rootでもない場合は、現在地を更新
      await refetchFolderInfo();
    }
    handleSnackbarCompleted('削除が完了しました');
  }, [
    folderPath,
    history,
    isCurrentFolder,
    isFolder,
    isLoadingFolderPath,
    isRootPage,
    props.data.object_type,
    props.data.org,
    props.data.parent_folder,
    props.data.uuid,
    refetchFolderInfo,
    refetchOrgDetail,
    refetchOrgRootList,
    setOpenSnackbar,
    setOpenSnackbarCollapse,
    setSnackbarMessage,
  ]);

  const onCancelDownload = async () => {
    // That does nothing for now -
    // TODO: implement download cancellation
    console.log('Download CANCELLED');
    // setSnackbarMessage('ダウンロードがキャンセルされました');
    return false;
  };

  const downloadData = useCallback(async () => {
    console.log('DOWNLOAD ダウンロード処理中');
    try {
      console.log('downloadData START');
      setProgressValue(0);
      setSnackbarMessage('ダウンロード処理中');
      setOpenSnackbar({ open: true, onClose: onCancelDownload });
      if (isFolder) {
        const downloadReturn = await downloadFolder(
          props.data.uuid,
          setProgressValue
        );
      } else {
        await downloadFile(props.data.uuid);
      }
      setSnackbarMessage('ダウンロードが完了しました');
    } catch (error) {
      setSnackbarMessage('エラーが発生しました');
      throwError(error);
    } finally {
      // after a delay, close the snackbar
      setTimeout(() => {
        setOpenSnackbar(false);
      }, 3000);
    }
  }, [
    isFolder,
    props.data.uuid,
    setOpenSnackbar,
    setProgressValue,
    setSnackbarMessage,
    throwError,
  ]);

  // メニュー一覧
  const menuItems = useMemo(() => {
    // console.log('context menuItems, EDIT:', canEdit, 'isConference', isConference, 'item props:', props.data);

    return [
      {
        // folder(series, study,isImgSeries)
        id: 'openViewer',
        name: 'ビューアーを開く3',
        icon: <PageviewOutlinedIcon style={itemStyle} />,
        disabled: !canView,
        display:
          !props.option.isNewButton &&
          (isDicom || isImgSeries || isImgFile || isFolder) &&
          !isConference,
        helptext: '',
        func: () => {
          props.onCloseContextMenu();
          openViewer({ newTab: true });
        },
      },
      {
        // Open DICOM data in Universal Viewer
        id: 'openViewUniversal',
        name: 'Universal Viewerで開く',
        icon: <PageviewOutlinedIcon style={itemStyle} />,
        disabled: !isDicom,
        display:
          !props.option.isNewButton &&
          (isDicom || isImgSeries || isImgFile || isFolder),
        helptext: '',
        func: () => {
          // Context menu item was selected.
          props.onCloseContextMenu();
          // URL for Universal Viewer container, which loads Universal Viewer in an iframe
          // pass application name if being opened from a Conference page
          let appTitle = null;
          if (isConference) {
            appTitle = CONFERENCE_APP_TITLE;
          }
          // else {
          //   appTitle = null;
          // }
          const univUrl = generateUniviewerUrl(props.data, appTitle);
          console.log('Open Universal', univUrl);
          const univWindow = window.open(univUrl);
        },
        // End of context menu item: Open DICOM data in Universal Viewer
      },
      {
        // Open all Patient images in Universal Viewer
        id: 'openViewUniversalPatient',
        name: '患者さんの全ての画像を開く',
        icon: <PageviewOutlinedIcon style={itemStyle} />,
        disabled: !isDicom,
        display:
          !props.option.isNewButton &&
          (isDicom || isImgSeries || isImgFile || isFolder) &&
          !isConference,
        helptext: '',
        func: () => {
          // Context menu item was selected.
          props.onCloseContextMenu();

          // URL for Universal Viewer as a component, with a specific patient
          const univUrlBase = '/universal-viewer';
          const univUrlparams =
            '?orgId=' +
            props.data.org +
            // + '&studyUId=' + props.data.uuid;
            '&patientId=' +
            props.data.PatientID;
          const universalViewer = window.open(univUrlBase + univUrlparams);
        },
      },

      {
        // root
        id: 'create-root-folder',
        name: 'フォルダを作成',
        icon: <CreateNewFolderOutlinedIcon style={itemStyle} />,
        disabled: !memberPerm,
        display:
          (props.option.isRoot || props.option.isTutorial) && !isConference,
        helptext: '',
        func: async () => {
          try {
            openCreateRootFolderDialog(props.option.rootPermName === 'org');
            props.onCloseContextMenu();
          } catch (e) {
            throwError(e);
          }
        },
      },
      {
        // folder(folder-strict)
        id: 'create-folder',
        name: 'フォルダを作成',
        icon: <CreateNewFolderOutlinedIcon style={itemStyle} />,
        disabled: !fullAccess,
        display: isFolderStrict && isCurrentFolder && !isConference,
        helptext: '',
        func: async () => {
          try {
            openCreateFolderDialog(props.data);
            props.onCloseContextMenu();
          } catch (e) {
            throwError(e);
          }
        },
      },
      {
        // folder(folder-strict)
        id: 'create-file-url',
        name: 'リンクファイルを作成',
        icon: <NoteAddOutlinedIcon style={itemStyle} />,
        disabled: !fullAccess,
        display: !props.option.isRoot && isFolderStrict && !isConference,
        helptext: '',
        func: async () => {
          try {
            openCreateURLFileDialog(props.data);
            props.onCloseContextMenu();
          } catch (e) {
            throwError(e);
          }
        },
      },
      {
        // root
        id: 'create-root-file-url',
        name: 'リンクファイルを作成',
        icon: <NoteAddOutlinedIcon style={itemStyle} />,
        disabled: !memberPerm,
        display: props.option.isRoot && !isConference,
        helptext: '',
        func: async () => {
          try {
            openCreateURLFileDialog({ uuid: '' });
            props.onCloseContextMenu();
          } catch (e) {
            throwError(e);
          }
        },
      },
      {
        // root, folder(folder-strict)
        id: 'fileUpload',
        name: 'ファイルをアップロード',
        icon: <CloudUploadOutlinedIcon style={itemStyle} />,
        disabled: !(
          (isFolderStrict && fullAccess) ||
          ((props.option.isRoot || props.option.isTutorial) && memberPerm)
        ),
        display:
          isFolderStrict || props.option.isRoot || props.option.isTutorial,
        helptext: '',
        func: () => {
          try {
            props.openDialogFile();
            props.onCloseContextMenu();
          } catch (e) {
            throwError(e);
          }
        },
      },
      {
        // root, folder
        id: 'folderUpload',
        name: 'フォルダをアップロード',
        icon: <CloudUploadOutlinedIcon style={itemStyle} />,
        disabled: !(
          (isFolderStrict && fullAccess) ||
          ((props.option.isRoot || props.option.isTutorial) && memberPerm)
        ),
        display:
          isFolderStrict || props.option.isRoot || props.option.isTutorial,
        helptext: '',
        func: () => {
          try {
            props.onCloseContextMenu();
            props.openDialogFolder();
          } catch (e) {
            throwError(e);
          }
        },
      },
      {
        // folder, file
        id: 'move',
        name: '指定の場所に移動',
        icon: <DoubleArrowOutlinedIcon style={itemStyle} />,
        disabled: !fullAccess,
        display: props.option.isDataRow && !isSeries && !isConference,
        helptext: '',
        func: e => {
          props.onCloseContextMenu();
          console.log('指定の場所に移動 context menu');
          openMovePopover(props.data, props.option);
        },
      },
      {
        // folder, file
        id: 'share',
        name: '共有リンク作成',
        icon: <LinkOutlinedIcon style={itemStyle} />,
        disabled: false,
        display:
          !props.option.isNewButton && !props.option.isRoot && !isConference,
        helptext: '',
        func: e => {
          props.onCloseContextMenu();
          console.log('共有リンク作成 context menu');
          openShareModal({
            data: props.data,
            funcWhenCompleted: () => async () => {
              await fetchUpdateInfo();
              if (props.data.parent_folder === null) {
                await refetchOrgRootList();
              }
            },
          });
        },
      },
      {
        // folder, file
        id: 'rename',
        name: '名前の変更',
        icon: <BorderColorOutlinedIcon style={itemStyle} />,
        disabled: !canEdit,
        display:
          !props.option.isNewButton && !props.option.isRoot && !isURLFile,
        helptext: '',
        func: () => {
          try {
            props.onCloseContextMenu();
            console.debug('名前の変更 context menu');
            openRenameDialog(props.data);
          } catch (e) {
            throwError(e);
          }
        },
      },
      {
        // folder, file
        id: 'rename-url',
        name: '名前、URLの変更',
        icon: <BorderColorOutlinedIcon style={itemStyle} />,
        disabled: !canEdit,
        display: !props.option.isNewButton && !props.option.isRoot && isURLFile,
        helptext: '',
        func: () => {
          try {
            props.onCloseContextMenu();
            openRenameURLFileDialog(props.data);
          } catch (e) {
            throwError(e);
          }
        },
      },
      {
        // folder(study, series)
        id: 'dicomTags',
        name: 'DICOMタグ付与',
        icon: <LabelOutlinedIcon style={itemStyle} />,
        helptext: '',
        disabled: !canComment,
        display: !props.option.isNewButton && isDicom && !isConference,
        func: () => {
          props.onCloseContextMenu();
          openDicomTagsDialog(props.data);
        },
      },
      {
        // folder, file
        id: 'download',
        name: 'ダウンロード',
        icon: <GetAppOutlinedIcon style={itemStyle} />,
        disabled: !canEdit,
        display: !props.option.isNewButton && !props.option.isRoot,
        helptext: '',
        func: () => {
          try {
            props.onCloseContextMenu();
            downloadData();
          } catch (e) {
            throwError(e);
          }
        },
      },
      {
        // folder(strict)
        id: 'folder2series',
        name: '画像シリーズに変換',
        icon: <BurstModeOutlinedIcon style={itemStyle} />,
        disabled: !canEdit,
        display: isFolderStrict && !props.option.isNewButton && !isConference,
        helptext: '',
        func: async () => {
          try {
            props.onCloseContextMenu();
            await folder2series(props.data.uuid);
            await fetchUpdateInfo();
            if (props.data.parent_folder !== null) {
              await refetchFolderInfo({
                folderUUID: props.data.parent_folder,
                apiOptions: { noFile: true, noUrl: true, combine: false },
              });
            }
          } catch (err) {
            if (
              err.response &&
              typeof err.response.data === 'object' &&
              'detail' in err.response.data
            ) {
              alert(err.response.data['detail']);
            } else {
              throwError(err);
            }
          }
        },
      },
      {
        // folder(imgSeries)
        id: 'series2folder',
        name: '画像シリーズを解除',
        icon: <BurstModeOutlinedIcon style={itemStyle} />,
        disabled: !canEdit,
        display: isImgSeries && !props.option.isNewButton && !isConference,
        helptext: '',
        func: async () => {
          try {
            props.onCloseContextMenu();
            await series2folder(props.data.uuid);
            await fetchUpdateInfo();
            if (props.data.parent_folder !== null) {
              await refetchFolderInfo({
                folderUUID: props.data.parent_folder,
                apiOptions: { noFile: true, noUrl: true, combine: false },
              });
            }
          } catch (err) {
            if (
              err.response &&
              typeof err.response.data === 'object' &&
              'detail' in err.response.data
            ) {
              alert(err.response.data['detail']);
            } else {
              throwError(err);
            }
          }
        },
      },
      {
        // folder, file
        id: 'delete',
        name: '削除',
        icon: <DeleteOutlineOutlinedIcon style={itemStyle} />,
        disabled: !canEdit,
        display: !props.option.isNewButton && !props.option.isRoot,
        helptext: '',
        func: async () => {
          try {
            props.onCloseContextMenu();
            console.log('削除 context menu');
            const deleted = await openDeleteDialog(props.data);
            console.log('Delete', deleted);
            if (deleted) {
              await deleteData();
            }
          } catch (e) {
            throwError(e);
          }
        },
      },
      {
        // folder (strict)
        id: 'folderSettings',
        name: 'フォルダの設定',
        icon: <SettingsOutlinedIcon style={itemStyle} />,
        disabled: !canEdit,
        display: isFolderStrict && !isConference,
        helptext: '',
        func: e => {
          props.onCloseContextMenu();
          // console.log(props.data);
          // openFolderSettingsDialog(props.data);
          openFolderSettingsDialog(props.data);
        },
      },
    ];
  }, [
    props,
    canView,
    isDicom,
    isImgSeries,
    isImgFile,
    isFolder,
    memberPerm,
    fullAccess,
    isFolderStrict,
    isCurrentFolder,
    isSeries,
    canEdit,
    isURLFile,
    canComment,
    openViewer,
    // LOOKREC_PROXY_URL,
    openCreateRootFolderDialog,
    throwError,
    openCreateFolderDialog,
    openCreateURLFileDialog,
    openMovePopover,
    openShareModal,
    fetchUpdateInfo,
    refetchOrgRootList,
    openRenameDialog,
    openRenameURLFileDialog,
    openDicomTagsDialog,
    downloadData,
    refetchFolderInfo,
    deleteData,
    openFolderSettingsDialog,
  ]);

  if (props.data.foldertype === 'study' && props.option.isNewButton) {
    return (
      <Menu
        keepMounted
        open={props.open}
        onClose={e => {
          e.preventDefault();
          props.onCloseContextMenu();
        }}
        onContextMenu={e => {
          e.preventDefault();
          props.onCloseContextMenu();
        }}
        anchorReference="anchorPosition"
        anchorPosition={props.option.position}
        TransitionComponent={Fade}
        autoFocus={false}
      >
        <div>
          <MenuList>
            <MenuItem>
              <Typography variant="body2" color="textSecondary">
                study 内ではアップロードはできません
              </Typography>
            </MenuItem>
          </MenuList>
        </div>
      </Menu>
    );
  }
  return (
    <>
      <Menu
        keepMounted
        open={props.open}
        onClose={e => {
          e.preventDefault();
          props.onCloseContextMenu();
        }}
        onContextMenu={e => {
          e.preventDefault();
          //props.onCloseContextMenu();
        }}
        anchorReference="anchorPosition"
        anchorPosition={props.option.position}
        TransitionComponent={Fade}
        autoFocus={false}
      >
        <MenuList>
          {menuItems.map(
            item =>
              item.display && (
                <MenuItem
                  key={item.id}
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    item.func(e);
                  }}
                  disabled={item.disabled}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText>{item.name}</ListItemText>
                  <Typography variant="body2" color="textSecondary">
                    {item.helptext}
                  </Typography>
                </MenuItem>
              )
          )}
        </MenuList>
      </Menu>
      {/* { (isDicom || isImgSeries || isImgFile) && (
        // Load the UniversalViewerLoader component, only if this item is DICOM data
        <UniversalViewerLoader data={props.data}/>
      )} */}
    </>
  );
}

ContextMenu.propTypes = {
  open: PropTypes.bool.isRequired,
  onCloseContextMenu: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  option: PropTypes.object.isRequired,
  openDialogFile: PropTypes.func.isRequired,
  openDialogFolder: PropTypes.func.isRequired,
};

// モーダルっぽく使いたい
const useContextMenu = () => {
  const setSelectDataCurrent = useSetSelectDataCurrent();
  const [contextMenuData, setContextMenuData] = React.useState({});
  const [contextMenuOption, setContextMenuOption] = React.useState({}); // {position:{ top: mouseY, left: mouseX }}

  // コンテキストメニューの開閉について
  const [open, setOpen] = React.useState(false);
  const onCloseContextMenu = useCallback(
    event => {
      setSelectDataCurrent();
      setOpen(false);
    },
    [setSelectDataCurrent]
  );
  const openContextMenu = useCallback((data, option) => {
    setContextMenuData(data);
    setContextMenuOption(option);
    setOpen(true);
  }, []);

  const contextMenu = useMemo(
    () =>
      function ContextMenuComponent({ openDialogFile, openDialogFolder }) {
        return (
          <ContextMenu
            open={open}
            onCloseContextMenu={onCloseContextMenu}
            data={contextMenuData}
            option={contextMenuOption}
            openDialogFile={openDialogFile}
            openDialogFolder={openDialogFolder}
          />
        );
      },
    [contextMenuData, contextMenuOption, onCloseContextMenu, open]
  );

  return [openContextMenu, contextMenu];
};

export default ContextMenu;
export { useContextMenu };
