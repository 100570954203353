/**
 * driveのようなデータ管理を行うメインページ
 *
 * This page is the Conference (学会用) wrapper for the S9 file manager.
 * Stripped down with many features removed, and some features added specifically
 * for conference use.
 *
 * Conference test page:
 * http://localhost/conference/org/8d4af03e-6203-4f01-91e5-8fb9f425d4ab/root/org
 *
 * For Conference users, we hide the public and private views on the left,
 * and the org root is also hidden. All files uploaded by Conference users
 * exist only in the org root, and the file listing for Conference shows
 * only those files in the org root.
 */

// TODO: 匿名化はどうする？
// TODO: handle user accessing /conference root URL with no orgid specified
// Done: update Lookrec viewer to support anon users
// Done: add a global on off switch (add to ConferenceExpiryContext.js)
// Done: only show link to conference page for org admin
// Done: redirect to conference root page, if conference is saved
// Done: copy correct URL for DICOM or other file
// Done: uploader widget
// Done: adjust context menu based on Conference
// Done: set global Expiry date at Conference level
// Done: add link to Conference page in Org menu
// Done: add a page to create a new Conference

import React, { useEffect, useContext, useState } from 'react';

import { withTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ModalContext } from '../../../../context/ModalContext';

import DataViewer from '../DataViewer/DataViewer';
import DrawerInfo from '../DrawerInfo';
import { useSelectData } from '../../utils/useSelectData';

import Hidden from '@material-ui/core/Hidden';
import { makeStyles } from '@material-ui/core/styles';

import { ExpiryDateProvider } from '../../providers/ConferenceExpiryContext';
import { useExpiryDate } from '../../providers/ConferenceExpiryContext';
import 'date-fns';
import { useLocation, useHistory, useParams } from 'react-router-dom';

import ConferenceDetails from './ConferenceDetails';
import CaseStudy from './CaseStudy';
import { CONFERENCE_APP_TITLE } from './ConferenceTitle';
import { AccessDenied } from '../../../../../../ui/src/components/errorBoundary/AccessDenied';

// Context for checked (selected) items in the AgGridTable (file/folder list)
// This allows us to make a Viewer URL for any selection of studies
import { MultiUrlProvider } from '../../providers/MultiUrlProvider';

// Function to check if we are on a Conference page
const isConferencePage = () => {
  const isConferencePath =
    window.location.pathname.startsWith('/conference/') ||
    window.location.hostname.startsWith('conference.');
  return isConferencePath;
};

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100%',
    width: '100%',
    backgroundColor: theme.color.bgColorSurface,
  },
  content: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.color.bgColorSurface,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    [theme.breakpoints.down('xs')]: {
      marginLeft: 0,
      marginRight: 0,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: -theme.drawerWidth,
    },
    zIndex: theme.zIndex.drawer + 1,
  },
  contentShift: {
    [theme.breakpoints.up('sm')]: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: `calc(100% - ${theme.drawerWidth}px)`,
      margin: 0,
      padding: 0,
      zIndex: theme.zIndex.drawer + 1,
    },
  },
  title: {
    flexGrow: 1,
    color: theme.palette.primary.main,
    margin: theme.spacing(2),
  },
  toolbar: {
    display: 'flex',
    padding: 0,
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    borderBottom: '1px solid #e0e0e0',
  },
  conferenceText: {
    margin: theme.spacing(1),
    padding: theme.spacing(1),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    width: '800px',
    padding: theme.spacing(2), // Add spacing around the form
    margin: theme.spacing(2),
  },
  textField: {
    margin: theme.spacing(1),
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: theme.spacing(2), // Add spacing between buttons
  },
  button: {
    borderRadius: '10px',
    // margin: '0.4rem',
    margin: theme.spacing(1),
  },
  conferenceContainer: {
    display: 'flex',
    padding: '1rem',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#fbfbfb',
    borderBottom: '1px solid #e0e0e0',
  },
  flexLeft: {
    flex: 1,
  },
  flexRight: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    marginLeft: 'auto',
    width: `200px`,
  },
}));

function StorageConference() {
  const classes = useStyles();
  const { openDrawer } = useContext(ModalContext);

  // If parentUUID exists in URL params, this is a CaseStudy
  const { initialOrgId, folderUUID, parentUUID } = useParams();
  console.debug(
    'StorageConference params',
    initialOrgId,
    folderUUID,
    parentUUID
  );
  const isCaseStudy = typeof parentUUID === 'string';

  // Access denied state is in this component, and passed to child components
  // Initialize to null so that nothing is displayed until API call completes
  const [accessDenied, setAccessDenied] = useState(null);

  useEffect(() => {
    // Save the original title to restore it when the component is unmounted
    const originalTitle = document.title;
    // Set the new title when the component is mounted
    document.title = CONFERENCE_APP_TITLE;

    // Clean up: reset the title back to its original value when the component is unmounted
    return () => {
      document.title = originalTitle;
    };
  }, []);

  // This is the JSX for a specific conference
  return (
    <div className={classes.root}>
      <div
        className={clsx(classes.content, {
          [classes.contentShift]: openDrawer,
        })}
      >
        {/*
          Wrap the ConferenceDetails and DataViewer components with the
          following React Context providers:
          - ExpiryDateProvider: global expiry data setting for the entire conference
          - MultiUrlProvider: provides a button to open any selected subset of
            DICOM data in a single Universal Viewer window
        */}
        <ExpiryDateProvider>
          <MultiUrlProvider>
            {/* Display ConferenceDetails OR CaseStudy depending on context */}
            {isCaseStudy ? (
              <CaseStudy
                accessDenied={accessDenied}
                setAccessDenied={setAccessDenied}
              />
            ) : (
              <ConferenceDetails
                accessDenied={accessDenied}
                setAccessDenied={setAccessDenied}
              />
            )}
            {/*
              Show nothing (return null) while waiting for API call on page contents.
              Show <AccessDenied /> if API returns 40x, 50x.
              Show data table component (DataViewer) if access OK.
            */}
            {accessDenied === null ? null : accessDenied ? (
              <AccessDenied />
            ) : (
              <DataViewer />
            )}
          </MultiUrlProvider>
        </ExpiryDateProvider>
        <Hidden xsDown>
          <DrawerInfo variant={'persistent'} />
        </Hidden>
        <Hidden smUp>
          <DrawerInfo variant={'temporary'} />
        </Hidden>
      </div>
    </div>
  );
}

StorageConference.propTypes = {};
export default withTranslation('Common')(StorageConference);
export { isConferencePage };
